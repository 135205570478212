import { Link } from "react-router-dom";

function Error(){

    return (
        <div> 

            <center><h1>Página não encontrada </h1></center>

            <br></br>
            <center><h2> <Link to="/"> Voltar </Link> </h2></center>
           

        </div>
    )
}

export default Error;