import "./header.css";
import { Link } from "react-router-dom";

function Hearder(){


    return (
        <header>
            <h1> TESTE HEADRES</h1>

            <Link className="logo" to="/">Prime flix</Link>
            <Link className="favoritos" to="/Favoritos">Meus favoritos</Link>

        </header>
    );
}

export default Hearder;