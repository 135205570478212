import { Link,useParams} from "react-router-dom";
import { useEffect, useState } from "react";
import {toast} from "react-toastify";

import api from "../../service/api";

function Filme(){

    const [filmes, setFilmes] = useState([]);
    const [loading, setloading] = useState(false);

    const {id} = useParams();


    //BUSCANDO DADOS APOS INICIAR O DOM UTILIZAMOS O CILCO DE VIDA USEFFECT

    useEffect(()=>{

       async  function loadFilmes(){

            const response = await api.get("movie/" + id,{
                params: {
                    api_key: "482b919ed4f98a2b8b647a9655bc47cc", 
                    language: "pt-BR",
                    page: 1
                }
            }).then((response) => {

                console.log(response.data)

                setFilmes(response.data)

                setloading(false)
            }).catch(() => {

                //CASO NAO ENCOTRAR O FILE

                alert("filme não encontrado")
                window.location.href = "/"; // Redirecionar para a página inicial
                return;
            });

        }



        loadFilmes()

        //ACOMPANHAR QUANDO O COMPONENTE E DESMONTADO OU SEJA QUANDO EU SAIR DA TELA

        return ()=>{
            console.log("COMPONENTE DESMONTADO----")
        }
    }, [id])


    function SalvarFilme(){

        const minhaLista = localStorage.getItem("@primeflix")

        let filmesSalvos = JSON.parse(minhaLista) || [];

        const hasfilme = filmesSalvos.some((filmesSalvos) => filmesSalvos.id === filmes.id)

        if(hasfilme){
            toast.warning("Este filme ja esta na lista")
            return;
        }


        filmesSalvos.push(filmes)
        localStorage.setItem("@primeflix", JSON.stringify(filmesSalvos))

        toast.success("Filme salvo com sucesso!")
    }

    if(loading){
        return (
            <div>
                <center>
                 <h1 > Carregando dados</h1>
                </center>
            </div>
        );
    }else{
        return (
            <div className="container">
                <div className="lista-filmes">
                    <strong> {filmes.title} </strong>
                    <img src={`https://image.tmdb.org/t/p/original/${filmes.poster_path}`} />
                    <h2>Sinopse</h2>
                    <span >{filmes.overview}</span>
                    <strong>Votaçã {filmes.vote_average} </strong>

                </div>

                <center>
                    <button onClick={SalvarFilme}>Salvar</button>

                    <div></div>

                    <a  target="blanck" rel="external" href={`https://www.youtube.com/results?search_query=${filmes.title} Trailer`}>
                        Trailer
                    </a>
                </center>
    
            </div>
        );
    }
  
}

export default Filme;