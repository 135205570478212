import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


import {toast} from "react-toastify";


function Favoritos(){

    const [filmes, setFilmes] = useState([]);    

    useEffect(() => {

        const minhaLista = localStorage.getItem("@primeflix");


        setFilmes(JSON.parse(minhaLista) || [] )
    },[])

    function ExcluirFilme(id){



        let filmesCorreto = filmes.filter( (item) =>{
            
            return (item.id !== id);
        })
    
        setFilmes(filmesCorreto)

        //ATUALIZAR ITEMS DO STORAGE
        localStorage.setItem("@primeflix",JSON.stringify(filmesCorreto))

        toast.success("Item delatdo com sucesso!")
    } 

    return (
        <div className="container">


        {

            filmes.length === 0  ? (<center> <h3>MEU QUERIDO VC NÃO NENHUM FILME SELECIONADO EM SEUS FAVORITOS </h3> </center>)
            : (<div></div>)
            
        }
        <div className="lista-filmes">
            {
                filmes.map((filmes) =>{

                    return (
                        <article key={filmes.id}>
                            <strong> {filmes.title} </strong>
                            <img src={`https://image.tmdb.org/t/p/original/${filmes.poster_path}`} />

                            <Link to={`/Filme/${filmes.id}`}> Acessar </Link>
                            <hr></hr>
                            <button  onClick={ ()=>{
                                ExcluirFilme(filmes.id)
                            }}>Deletar</button>
                        </article>
                    )
                })
            }
        </div>

    </div>
    )
}

export default Favoritos;