import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./home.css";
import api from "../../service/api";

function Home(){

    const [filmes, setFilmes] = useState([]);
    const [loading, setloading] = useState(true);


    //BUSCANDO DADOS APOS INICIAR O DOM UTILIZAMOS O CILCO DE VIDA USEFFECT

    useEffect(()=>{

        async  function loadFilmes(){

            const response = await api.get("movie/now_playing",{
                params: {
                    api_key: "482b919ed4f98a2b8b647a9655bc47cc", 
                    language: "pt-BR",
                    page: 1
                }
            });


            console.log(response.data.results.slice(0,10))

            setFilmes(response.data.results.slice(0,10))

            setloading(false)
        }

        loadFilmes()

    }, [])

    if(loading){
        return (
            <div>
                <center>
                 <h1 > Carregando dados</h1>
                </center>
            </div>
        );
    }else{
        return (
            <div className="container">
                <div className="lista-filmes">
                    {
                        filmes.map((filmes) =>{
    
                            return (
                                <article key={filmes.id}>
                                    <strong> {filmes.title} </strong>
                                    <img src={`https://image.tmdb.org/t/p/original/${filmes.poster_path}`} />
    
                                    <Link to={`/Filme/${filmes.id}`}> Acessar </Link>
                                </article>
                            )
                        })
                    }
                </div>
    
            </div>
        );
    }
  
}

export default Home;