import RouteApp from "./routes";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function App() {

  // IMPORTA O TOASTE CONTAINER
  return (
    <div className="App">
      <ToastContainer autoClose={3000}/>
      <RouteApp/>
    </div>
  );
}

export default App;
