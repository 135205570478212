import {BrowserRouter, Routes, Route} from "react-router-dom";

import Filme from "./page/Filmes";
import Home from "./page/Home";
import Hearder from "./components/Header";
import Error from "./Error";
import Favoritos from "./page/Favoritos";

function RouteApp(){

    return <BrowserRouter>
        <Hearder/>
        <Routes>

            <Route path="/Favoritos"  element={<Favoritos/>}/>
            <Route path="/Filme/:id"  element={<Filme/>}/>
            <Route path="/"  element={<Home/>}/>

            <Route path="*"  element={<Error/>}/>
        </Routes>
    </BrowserRouter>
}

export default RouteApp;